import $ from 'jquery';

export default function shortenText (
        selector='[data-wordcount]',
        dataAttributeName="wordcount",
        options={elipsis:true, moreLabel: 'Show more', lessLabel: 'Show less'}
    ) {

    const items = $(selector);

    items.each((index, element) => {
        const el = $(element);
        const text = el.text().trim();
        const maxWords = el.data(dataAttributeName);
        const words = text.split(' ');
        const count = words.length;
        let shortText = words.splice(0, maxWords).join(' ');

        if (count > maxWords) {
            if (options.elipsis && maxWords > 0) {
              shortText += "...";
            }

            const moreLabel = options.moreLabel;
            const lessLabel = options.lessLabel;
            const trigger = $(`<button class="button button--tertiary button--inline">${moreLabel}</button>`);
            const truncated = $(`<span data-truncated>${shortText}</span>`);
            const full = $(`<span data-full>${text}</span>`);

            el.html(truncated)
                .append(full)
                .append(trigger);

            full.toggle();

            trigger.on('click', e => {
                if (shortText) {
                    truncated.toggle();
                }
                full.toggle();
                trigger.text(trigger.text() == moreLabel ? lessLabel : moreLabel);
            });
        }
    });
}
