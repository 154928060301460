import $ from 'jquery';
// import skipLinks from './utils/skipLinks';
import iframer from './utils/iframer';
import mNav from './utils/mNav';
import chapterNav from './utils/chapterNav';
import childTabIndex from './utils/childTabIndex';
import searchExpand from './utils/searchExpand';
import personBio from './utils/personBio';
import setupSharing from './utils/social';
import copyText from './utils/copyText';
import addTrackingToChildren from './utils/gaAddTrackingToChildren';
// import gaExtras from './utils/gaExtras';
// import gaExtrasEventNoLink from './utils/gaExtrasEventNoLink';
// import scrollTrack from './utils/scrollTrack';
import countryNav from './utils/countryNav';
import profileNav from './utils/profileNav';
import sectionSharing from './utils/sectionSharing';
import menuMultiple from './utils/menuMultiple';
import checkboxGroupSelectToggle from './utils/checkboxGroupSelectToggle';
import clearAllCheckboxes from './utils/clearAllCheckboxes';
import entitySearch from './utils/entitySearch';
import chartGroup from './charts/chartGroup';
import equalHeight from './utils/equalHeight';
import equalHeightAll from './utils/equalHeightAll';
import footnotesShowHide from './utils/footnotesShowHide';
import reveal from './utils/reveal';
import setupSticky from './utils/setupSticky';
import listShowHide from './utils/listShowHide';
import shortenText from './utils/shortenText';

// expose jQuery for other bundles
if (typeof window['jQuery'] === 'undefined') {
    window['jQuery'] = $;
}

function globals () {

    // iframe video in body content
    iframer();

    // Filter
    menuMultiple(
        '.filter-trigger',
        'filter-trigger--active',
        '.filter',
        'filter--active'
    );

    // Small Screen Navigation
    mNav(
        '#navigation-primary-toggle',
        'navigation-primary-toggle--active',
        '#navigation-primary',
        'navigation-primary--active'
    );

    // Shorten text
    shortenText();
    shortenText('[data-explanation]', 'maxwords', {
        elipsis: false,
        moreLabel: 'Why this status?',
        lessLabel: 'Hide status info'
    });

    // Read more
    reveal();

    // Set child tab index for nav
    childTabIndex(
        '#navigation-primary-toggle',
        '#navigation-primary',
        'navigation-primary--active',
        960
    );

    // Search showHide
    mNav(
        '#search-trigger',
        '',
        '.search',
        'search--active'
    );

    // Search expand
    searchExpand(
        '.js-search',
        '.js-search',
        'js-search--active'
    );

    // Countries menu
    countryNav(
        '#js-countries-menu-container',
        '#js-countries-menu',
        'countries-menu-list__item--open',
        '.countries-menu-list__item--parent-third',
        'countries-menu-list__countries',
        'countries-menu-list__countries--selected',
        'countries-menu-list--selected',
        '.js-countries-menu-trigger',
    );

    // Profile nav
    profileNav();
    setupSticky();

    // Chapter nav
    chapterNav();

    // Events filter
    mNav(
        '.filterBlock__title',
        'active',
        '.filters__wrap',
        'active'
    );

    // Set child tab index for nav
    childTabIndex(
        '.filterBlock__title',
        '.filters__wrap',
        'active',
        780
    );

    // Person bio
    personBio(
        '.personTeaser',
        'active',
        '.js-person-swap',
        '.js-person-label',
        'swap',
    );

    // checkbox group toggling
    checkboxGroupSelectToggle();

    // clear filter
    clearAllCheckboxes();

    // entity autocomplete
    entitySearch();

    // footnotes toggle
    footnotesShowHide();

    // social
    setupSharing();
    copyText(
        '.js-copy-field',
        '.js-copy-trigger'
    );
    sectionSharing();

    // equal height chart titles
    equalHeight('.l-2up, .l-3up, .l-4up, .l-2-3up', '.m-intro__subheading');
    equalHeight('.l-2up-float', '.personTeaser');

    // equal height stakeholder page
    // equalHeightAll('.l-sidebar__body', '.personTeaser');

    // simple profile charts
    chartGroup();

    // Google Analytics extras
    addTrackingToChildren('.js-add-tracking-to-children');
    /* Removed - now using GTM for this
    gaExtras('.js-ga-download-click, .js-social-share, .js-ga-event-link');
    gaExtrasEventNoLink('.js-ga-tab-switch');
    scrollTrack('#body-report','.section-chapter');
    scrollTrack('#body-profiles','section.section'); */

    // list toggles
    listShowHide();
}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();
});
